import KantarWhite from "./tgi-smart/kantar-white.svg";
import TgiSmart from "./tgi-smart/tgi-smart.svg";

import Eop from "./energia-na-oszczedzanie/eop.svg";
import EopWhite from "./energia-na-oszczedzanie/eop-white.svg";
import SmartGridWhite from "./energia-na-oszczedzanie/smart-grid-white.svg";

import Edf from "./ems-edf/edf.svg";
import EdfWhite from "./ems-edf/edf-white.svg";

import Kellner from "./kellner/kellner.svg";
import KellnerWhite from "./kellner/kellner-white.svg";
import SKreatywna from "./kellner/skreatywna.svg";

import Alternator from "./alternator-website/alternator.svg";
import AlternatorWhite from "./alternator-website/alternator-white.svg";
import Studio2x2White from "./alternator-website/studio2x2-white.svg";

import Picnook from "./picnook/logo.svg";
import PicnookWhite from "./picnook/logo-white.svg";

import CDAction from "./cdaction/logo.svg";
import CDActionWhite from "./cdaction/logo-white.svg";
import PerformanteWhite from "./cdaction/performante-white.svg";

export default {
  KantarWhite,
  TgiSmart,
  Eop,
  EopWhite,
  SmartGridWhite,
  Edf,
  EdfWhite,
  Kellner,
  KellnerWhite,
  SKreatywna,
  Alternator,
  AlternatorWhite,
  Studio2x2White,
  Picnook,
  PicnookWhite,
  CDAction,
  CDActionWhite,
  PerformanteWhite,
};
